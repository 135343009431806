import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row } from "react-bootstrap"
import contact from "../images/contact.png"
import locate from "../images/locate.png"
import follow from "../images/follow.png"

const Contact = () => {
  return (
    <Layout pageName="Contactcy">
      <SEO title="Contact" />
      <div className="bg-sec py-5">
        <Container>
          <p className="text-center fi-head">Contact Us</p>
          <div className="col-md-10 offset-md-1 px-0">
            <Row className="mt-md-5">
              <div className="col-md-5 valign-center">
                <img src={contact} className="w-100" />
              </div>
              <div className="col-md-7 pt-4 py-md-5  space-between">
                <p className="ed-title mb-3">MUMBAI (REGD. OFFICE)</p>
                <p className="fi-txt">
                  Teach For India
                  <br />
                  Godrej One, 2nd Floor
                  <br />
                  Pirojshanagar, <br />
                  Eastern Express Highway
                  <br />
                  Vikhroli (East), Mumbai - 400079
                  <br />
                </p>
                <p className="fi-txt mb-md-4">
                  <a href="tel:+91 22 25194283" className="fi-link-con">
                    +91 22 25194283
                  </a>
                  <br />

                  <a href="tel:+91 22 25194284" className="fi-link-con">
                    +91 22 25194284
                  </a>
                </p>
                <div>
                  <a
                    href="mailto:mumbaicityops@teachforindia.org"
                    className="blackButn"
                  >
                    Email Us
                  </a>
                  <a
                    href="https://goo.gl/maps/xWgSbBCSWh42"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="blackButn"
                  >
                    Find Us
                  </a>
                </div>
              </div>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="pb-5 mb-md-5">
        <Row className="mx-0">
          <div className="col-lg-4 col-md-6 contact-card card-light-green">
            <p className="con-title">MUMBAI (HEAD OFFICE)</p>
            <p className="fi-txt mt-md-4 mb-md-3">
              Teach For India
              <br />
              Godrej One, 2nd Floor Pirojshanagar, Eastern Express Highway
              Vikhroli (East), Mumbai - 400079
            </p>
            <p className="fi-txt mt-md-4 mb-md-3">
              <a href="tel:+91 22 25194283" className="fi-link-con">
                +91 22 25194283
              </a>
              <br />

              <a href="tel:+91 22 25194284" className="fi-link-con">
                +91 22 25194284
              </a>
            </p>
            <div className="contact-links">
              <a
                href="https://goo.gl/maps/xWgSbBCSWh42"
                target="_blank"
                rel="noreferrer noopener"
                className="conButn"
              >
                <img src={locate} />
                Find us
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 contact-card card-dark-green">
            <p className="con-title">PUNE</p>
            <p className="fi-txt mt-md-4 mb-md-3">
              Teach For India
              <br />
              Row House #4, Goldfields Enclave South Main Road, Koregaon Park
              Pune - 411001
            </p>
            <p className="fi-txt mt-md-4 mb-md-3">
              <a href="tel:+91 20 64000252" className="fi-link-con">
                +91 20 64000252
              </a>
              <br />

              <a href="tel:+91 20 64001558" className="fi-link-con">
                +91 20 64001558
              </a>
            </p>
            <div className="contact-links">
              <a
                href="https://goo.gl/maps/KxwCioQhQyt"
                target="_blank"
                rel="noreferrer noopener"
                className="conButn"
              >
                <img src={locate} />
                Find us
              </a>
              <a
                href="https://www.instagram.com/teachforindiapune/?hl=en"
                target="_blank"
                rel="noreferrer noopener"
                className="conButn"
              >
                <img src={follow} />
                Follow us
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 contact-card card-red">
            <p className="con-title">CHENNAI</p>
            <p className="fi-txt mt-md-4 mb-md-3">
              Teach For India
              <br />
              Cabin no. 126, The Executive Zone Tower #1, Shakti Towers 766,
              Anna Salai Chennai - 600002
            </p>
            <p className="fi-txt mt-md-4 mb-md-3">
              <a href="tel:+91 044 40912517" className="fi-link-con">
                +91 044 40912517
              </a>
              <br />
              &nbsp;
            </p>
            <div className="contact-links">
              <a
                href="https://g.page/The-Executive-Zone?share"
                target="_blank"
                rel="noreferrer noopener"
                className="conButn"
              >
                <img src={locate} />
                Find us
              </a>
              <a
                href="https://www.instagram.com/teachforindia.chennai/?hl=en"
                target="_blank"
                rel="noreferrer noopener"
                className="conButn"
              >
                <img src={follow} />
                Follow us
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 contact-card card-pink">
            <p className="con-title">NEW DELHI</p>
            <p className="fi-txt mt-md-4 mb-md-3">
              Teach For India
              <br />
              N-23, Second Floor
              <br /> Green Park Extn.
              <br /> New Delhi - 110016
            </p>
            <p className="fi-txt mt-md-4 mb-md-3">
              <a href="tel:+91 11 40543751" className="fi-link-con">
                +91 11 40543751
              </a>
              <br />

              <a href="tel:+91 11 65658666" className="fi-link-con">
                +91 11 65658666
              </a>
            </p>
            <div className="contact-links">
              <a
                href="https://goo.gl/maps/Eg2PzFatYCu"
                target="_blank"
                rel="noreferrer noopener"
                className="conButn"
              >
                <img src={locate} />
                Find us
              </a>
              <a
                href="https://www.instagram.com/teachforindiadilli/?hl=en"
                target="_blank"
                rel="noreferrer noopener"
                className="conButn"
              >
                <img src={follow} />
                Follow us
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 contact-card card-blue">
            <p className="con-title">HYDERABAD</p>
            <p className="fi-txt mt-md-4 mb-md-3">
              Teach For India
              <br />
              #147, Panchavati Colony Road No. 10, Banjara Hills Hyderabad,
              Telangana - 500034
            </p>
            <p className="fi-txt mt-md-4 mb-md-3">
              <a href="tel:+91 40 23395707" className="fi-link-con">
                +91 40 23395707
              </a>
              <br />
              &nbsp;
            </p>
            <div className="contact-links">
              <a
                href="https://goo.gl/maps/6YJFyKPeeqD2"
                target="_blank"
                rel="noreferrer noopener"
                className="conButn"
              >
                <img src={locate} />
                Find us
              </a>
              <a
                href="https://www.instagram.com/teachforindiahyderabad/?hl=en"
                target="_blank"
                rel="noreferrer noopener"
                className="conButn"
              >
                <img src={follow} />
                Follow us
              </a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 contact-card card-yellow">
            <p className="con-title">AHMEDABAD</p>
            <p className="fi-txt mt-md-4 mb-md-3">
              Teach For India
              <br />
              10 Suvarnagar Bungalows, Kamnath Mahadev Road, Memnagar, Ahmedabad
              380013
            </p>
            <p className="fi-txt mt-md-4 mb-md-3">
              <a href="tel:+91 79 27910445" className="fi-link-con">
                +91 79 27910445
              </a>
              <br />
              <a href="tel:+91 79 65250005" className="fi-link-con">
                +91 79 65250005
              </a>
            </p>
            <div className="contact-links">
              <a
                href="https://goo.gl/maps/tf7kWjm9m43pXU9U8"
                target="_blank"
                rel="noreferrer noopener"
                className="conButn"
              >
                <img src={locate} />
                Find us
              </a>
              <a
                href="https://www.instagram.com/teachforindia_ahmedabad/?hl=en"
                target="_blank"
                rel="noreferrer noopener"
                className="conButn"
              >
                <img src={follow} />
                Follow us
              </a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 contact-card card-orange">
            <p className="con-title">BENGALURU</p>
            <p className="fi-txt mt-md-4 mb-md-3">
              Teach For India
              <br />
              Commercial Premises No. 259 2nd floor, 3rd cross, 1st Main Color,
              2nd Stage, Domlur, Bangalore. Karnataka - 560071
            </p>
            <p className="fi-txt mt-md-4 mb-md-3">
              <a href="tel:+91 080 41320015" className="fi-link-con">
                +91 080 41320015
              </a>
              <br />
              &nbsp;
            </p>
            <div className="contact-links">
              <a
                href="https://goo.gl/maps/Ype8u6ticZ42"
                target="_blank"
                rel="noreferrer noopener"
                className="conButn"
              >
                <img src={locate} />
                Find us
              </a>
              <a
                href="https://www.instagram.com/teachforindiablr/?hl=en"
                target="_blank"
                rel="noreferrer noopener"
                className="conButn"
              >
                <img src={follow} />
                Follow us
              </a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 contact-card card-dark-green">
            <p className="con-title">KOLKATA</p>
            <p className="fi-txt mt-md-4 mb-md-3">
              Stay tuned.
            </p>
          </div>
        </Row>
      </Container>
    </Layout>
  )
}

export default Contact
